<template>
  <div id="login" style="min-height: calc(100vh - 20px)" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh">
            <b-col
              lg="6"
              class="bg-primary d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center">
                <div class="p-5">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="12" xl="12">
                      <div>
                        <h2 class="display-9 text-white fw-medium">
                          HooH IoT 단지 관리자
                        </h2>
                        <p class="mt-12 text-white op-5 font-weight-normal">
                          단지 관리사무소 관계자만 로그인 할 수 있습니다.<br/>단지 구성원 또는 일반 사용자는 HooH IoT 앱을 이용해주세요.<br/><br/>문의 010-2786-3623
                        </p>
                       
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col
              lg="6"
              class="d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center w-100">
                <div class="p-5 w-100">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="6">
                      <h2 class="font-weight-bold mt-4">로그인</h2>
                      <br/>

                      <b-form @submit.prevent="submit">
                        <b-form-group>
                          <b-form-input
                            id="txt-aptId"
                            type="text"
                            placeholder="단지ID"
                            class="mb-3"
                            v-model="form.aptId"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            id="txt-userId"
                            type="text"
                            placeholder="사용자ID"
                            class="mb-3"
                            v-model="form.userId"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            id="txt-password"
                            type="password"
                            placeholder="비밀번호"
                            class="mb-3"
                            v-model="form.password"
                          ></b-form-input>
                        </b-form-group>
                        <b-button
                          type="submit"
                          variant="primary"
                          size="lg"
                          block
                          class="mt-4"
                          >로그인</b-button
                        >
                      </b-form>
                    
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { login } from '../../api';
export default {
  name: "FullLogin",
  data: () => ({
    form: {
      aptId: "",
      userId: "",
      password: ""
    }
  }),
  computed: {},
  methods: {
    async submit(){
      if(this.form.aptId == "")
      {
        this.$bvToast.toast('단지ID를 입력하세요.', {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }
      if(this.form.userId == "")
      {
        this.$bvToast.toast('사용자ID를 입력하세요.', {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }
      if(this.form.password == "")
      {
        this.$bvToast.toast('비밀번호를 입력하세요.', {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }

      const loginRes = await login(this.form);
      if(loginRes.error){
        this.$bvToast.toast(loginRes.message, {
          title: `오류`,
          variant: "danger",
          solid: true
        })
      }else{
        await localStorage.setItem('ACCESS_TOKEN', loginRes.accessToken);
        await localStorage.setItem('REFRESH_TOKEN', loginRes.refreshToken);
        this.$router.replace("/starter");
      }
     
    }
  },
};
</script>